<i18n locale="th" lang="yaml" >
page.title : "{name} :: แก้ไขข้อมูลผู้ใช้งาน"
page.description : "แก้ไขข้อมูลของผู้ใช้งานในระบบ"
field.username : "ชื่อผู้ใช้งาน"
field.username.placeholder : "ระบุชื่อผู้ใช้งาน"
field.user_role : "ตำแหน่งผู้ใช้งาน"
field.full_name : "ชื่อ-นามสกุล"
field.full_name.placeholder : "ชื่อ-นามสกุลของผู้ใช้งาน"
field.email : "Email"
field.email.placeholder: "ระบุ Email ที่ใช้ในการติดต่อ และ ส่งข้อมูล"
field.phone : "เบอร์โทรศัพท์"
field.phone.placeholder: "ระบุเบอร์โทรศัพท์มือถือ เพื่อใช้ในการติดต่อ"
field.image.help : "รูปผู้ใช้งาน ควรมีขนาด อย่างน้อย 256x256 px"
update_user.success : "ทำการแก้ไขข้อมูลของ {name} เรียบร้อย"
radio.admin: "1"
radio.user: "2"
radio.technician: "3"
</i18n>
<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: user.username })"
			:description="$t('page.description')"/>

		<a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
			<a-form
				layout="horizontal"
				:label-col="labelCol"
				:wrapper-col="wrapperCol"
				:form="formObj"
				@submit.prevent="handleSubmit">

				<a-form-item :help="$t('field.image.help')"
					style="text-align:center;margin-bottom:16px;"
					:label-col="{ span: 0 }"
					:wrapper-col="{ span: 24 }">
						<ImageUploader
							:current-image-url="userImageDisplay"
							border-type="circle"
							size="large"
							:max-width="512"
							:max-height="512"
							@error="handleFileUploadError"
							@change="hadleFileUploadChange">
							<a-icon type="user" />
						</ImageUploader>
					</a-form-item>

				<a-form-item :label="$t('field.username')">
					<a-input
						v-if="isMe"
						v-decorator="[
							'username' ,
							{ rules: [
								{ required: true, message: $tt('validate.required','field.username') },
								{ min: 3 ,message: $tt('validate.length','field.username',{ min: 3,max: 100 }) } ,
								{ max: 100 ,message: $tt('validate.length','field.username',{ min: 3,max: 100 }) } ,
							] },
						]"
						type="text"
						autocomplete="off"
						:placeholder="$t('field.username.placeholder')">
						<a-icon
							slot="prefix"
							type="user"/>
					</a-input>
					<span v-else class="ant-form-text">
						{{user.username}}
					</span>
				</a-form-item>

				<a-form-item
					v-show="canChangeUserRole" :label="$t('field.user_role')">
					<a-radio-group
						v-decorator="[
							'user_role' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','field.user_role') },
							] },
						]"
						:disabled="!canChangeUserRole">
						<a-radio value="admin">{{$tenum('user_role',$t('radio.admin'))}}</a-radio>
						<a-radio value="user">{{$tenum('user_role',$t('radio.user'))}}</a-radio>
						<a-radio value="technician">{{$tenum('user_role',$t('radio.technician'))}}</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item :label="$t('field.full_name')">
					<a-input
						v-decorator="[
							'full_name' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','field.full_name') },
							] },
						]"
						type="text"
						:placeholder="$t('field.full_name.placeholder')">
						<a-icon slot="prefix" type="user" />
					</a-input>
				</a-form-item>
				<a-form-item :label="$t('field.phone')">
					<a-input
						v-decorator="['phone']"
						type="tel"
						:max-length="20"
						:placeholder="$t('field.phone.placeholder')">
						<a-icon slot="prefix" type="phone" />
					</a-input>
				</a-form-item>

				<a-form-item :label="$t('field.email')">
					<a-input
						v-decorator="[
							'email' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','field.email') },
							] },
						]"
						type="email"
						:placeholder="$t('field.email.placeholder')">
						<a-icon slot="prefix" type="mail" />
					</a-input>
				</a-form-item>

				<a-form-item v-bind="wrapperOnlyLayout">
					<a-button size="large" icon="left" :disabled="formLoading" style="margin-right:4px" @click="goBack">
							{{$t('common.back')}}
						</a-button>
					<a-button type="info" html-type="submit" class="btn-submit" size="large" :loading="formLoading">
						<a-icon type="save" /> {{$t('common.save')}}
					</a-button>
				</a-form-item>

			</a-form>
		</a-card>
	</div>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import ImageUploader from '@components/input/ImageUploader.vue'
import PageMixin from   '@mixins/PageMixin.vue'
import axios from "axios"
import {mapActions } from 'vuex'
import {updateFieldsValue} from "@utils/formUtil.js"
import {Radio} from "ant-design-vue"
import ApiError from '@utils/errors/ApiError'
export default {
	page() {
		return {
			title: this.$t('page.title',{name:this.user.username})
		}
	} ,
	components : {
		ImageUploader,
		"a-radio" : Radio,
		"a-radio-group" : Radio.Group,
	} ,
	mixins: [PageMixin,HasAntdFormMixin,LoginInfoMixin],
	data() {
		return {
			user : {} ,
			company : {} ,
			imageUploadData : {} ,
		}
	} ,
	computed : {
		userImageDisplay() {
			return this.user ? this.user.image_url : null
		} ,
		canChangeUserRole() {
			return this.user.id !== this.currentUser.id && this.user.user_role !== 3
				&& this.$authorize('update','user',{companyId : this.user.company_id})
		} ,
		isMe() {
			return this.user.id == this.currentUser.id
		}
	} ,
	watch: {
		$route : 'fetchData' ,
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		...mapActions('auth',['updateCurrentUser']) ,
		handleFileUploadError(error) {
			this.$message.error(ApiError.getDisplayErrorMessage(this,error));
		} ,
		hadleFileUploadChange(value) {
			this.imageUploadData = value;
		} ,
		fetchData() {
			const userId = this.$route.params.id ? this.$route.params.id : this.currentUser.id;

			this.showPageLoading(true)
			this.clearBreadcrumbParams(['companyName','companyId','userName'])
			axios.get("/api/users/"+userId).then((response)=>{
				const user = response.data.data.user;
				const company = response.data.data.company;

				if (!this.$authorize('update','user',{companyId:user.company_id})) {
					this.$pageError({name:'error_forbidden'})
					return;
				}

				this.user = user;
				const userRole = ['admin','user','technician']
				this.$nextTick(() => {
					updateFieldsValue(this.formObj,this.user)
					this.formObj.setFieldsValue({user_role: userRole[user.user_role-1]})
				})

				this.addBreadcrumbParams({
					companyName : company.name ,
					companyId : company.id,
					userName : this.user.username
				})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSubmit() {
			this.formObj.validateFields((err, values) => {
				if (!err) {
					this.formLoading = true
					const formData = {
						...values ,
						'image_file_base64' : this.imageUploadData
					};

					axios.put("/api/users/"+this.user.id,formData).then((response) => {
						const newUser = response.data.data.user;

						this.updateCurrentUser(newUser);
						this.$router.push({
							name : 'user/base',
							params : {id : this.user.id}
						});
						this.$message.success(this.$t('update_user.success',{"name" : newUser.username}))
					}).catch((error) => {
						this.formSubmitErrors(error)
					}).finally(()=>{
						this.formLoading = false;
					});
				} else {
					this.displayValidateErrorMessage()
				}
			});
		} ,
		goBack() {
			this.$open({name:'user/base' , params : {id : this.user.id}})
		}
	} ,

}
</script>
